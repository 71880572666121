<template>
  <div :class="$style.select">
    <a-dropdown
      v-if="type === 'single' && !showData.length"
      :get-popup-container="
        triggerNode => {
          return triggerNode.parentNode.parentNode
        }
      "
      :placement="_placement"
    >
      <div :class="$style.box" @click="onAdd()">
        <i :class="$style.icon" class="ri-add-line" />
      </div>
      <div slot="overlay">
        <div :class="$style.drop">
          <div :class="$style.dropBtn" @click="onAdd()">
            <i class="ri-image-line" />
            素材库选择
          </div>
          <div :class="$style.line" />
          <p :class="$style.dropTip">从本地选择</p>
          <div :class="$style.dropType">
            <div :class="allowed('image') ? $style.dropItem : $style.dropItemNo" @click="onLoad('image')">
              <i class="ri-image-line 2" />
              <p>图片</p>
            </div>
            <div :class="allowed('audio') ? $style.dropItem : $style.dropItemNo" @click="onLoad('audio')">
              <i class="ri-music-line" />
              <p>音频</p>
            </div>
            <div :class="allowed('video') ? $style.dropItem : $style.dropItemNo" @click="onLoad('video')">
              <i class="ri-play-circle-line" />
              <p>视频</p>
            </div>
          </div>
        </div>
      </div>
    </a-dropdown>

    <a-dropdown
      v-if="type === 'single' && showData.length"
      :get-popup-container="
        triggerNode => {
          return triggerNode.parentNode.parentNode
        }
      "
      :visible="visible"
      :placement="_placement"
    >
      <div :class="$style.img">
        <img :src="showData[0].cover" />
        <i v-if="showData[0].type === 'video'" :class="$style.type" class="ri-play-circle-line" />
        <i v-if="showData[0].type === 'audio'" :class="$style.type" class="ri-music-line" />
        <div :class="$style.btn">
          <i class="ri-eye-line" @click="onPreview(showData)" />
          <i class="ri-edit-line" @click="onEdit()" />
        </div>
      </div>
      <div slot="overlay">
        <div v-click-outside="onClose" :class="$style.drop">
          <div :class="$style.dropBtn" @click="onAdd()">
            <i class="ri-image-line" />
            素材库选择
          </div>
          <div :class="$style.line" />
          <p :class="$style.dropTip">从本地选择</p>
          <div :class="$style.dropType">
            <div :class="allowed('image') ? $style.dropItem : $style.dropItemNo" @click="onLoad('image')">
              <i class="ri-image-line 2" />
              <p>图片</p>
            </div>
            <div :class="allowed('audio') ? $style.dropItem : $style.dropItemNo" @click="onLoad('audio')">
              <i class="ri-music-line" />
              <p>音频</p>
            </div>
            <div :class="allowed('video') ? $style.dropItem : $style.dropItemNo" @click="onLoad('video')">
              <i class="ri-play-circle-line" />
              <p>视频</p>
            </div>
          </div>
        </div>
      </div>
    </a-dropdown>

    <a-dropdown
      v-if="type === 'multiple'"
      :get-popup-container="
        triggerNode => {
          return triggerNode.parentNode.parentNode
        }
      "
      :placement="_placement"
    >
      <div :class="$style.box" @click="onAdd()">
        <i :class="$style.icon" class="ri-add-line" />
      </div>
      <div slot="overlay">
        <div :class="$style.drop">
          <div :class="$style.dropBtn" @click="onAdd()">
            <i class="ri-image-line" />
            素材库选择
          </div>
          <div :class="$style.line" />
          <p :class="$style.dropTip">从本地选择</p>
          <div :class="$style.dropType">
            <div :class="allowed('image') ? $style.dropItem : $style.dropItemNo" @click="onLoad('image')">
              <i class="ri-image-line 2" />
              <p>图片</p>
            </div>
            <div :class="allowed('audio') ? $style.dropItem : $style.dropItemNo" @click="onLoad('audio')">
              <i class="ri-music-line" />
              <p>音频</p>
            </div>
            <div :class="allowed('video') ? $style.dropItem : $style.dropItemNo" @click="onLoad('video')">
              <i class="ri-play-circle-line" />
              <p>视频</p>
            </div>
          </div>
        </div>
      </div>
    </a-dropdown>
    <div v-if="type === 'multiple' && showData.length" :class="$style.flex1">
      <div v-for="(item, index) in showData" :key="index" :class="$style.img">
        <img :src="item.cover" />
        <i v-if="item.type === 'video'" :class="$style.type" class="ri-play-circle-line" />
        <i v-if="item.type === 'audio'" :class="$style.type" class="ri-music-line" />
        <div :class="$style.btn">
          <i class="ri-eye-line" @click="onPreview(item)" />
          <i class="ri-delete-bin-7-line" :class="$style.delete" @click="onDelete(index)" />
        </div>
      </div>
    </div>
    <chooseMaterial
      :visible.sync="chooseVisible"
      :multiple="type === 'multiple'"
      :allow="allow"
      @getChoose="getChoose"
    />
    <uploadModal :type="mode" :return-change="true" :visible.sync="uploadVisible" @change="onChange" />
  </div>
</template>

<script>
import chooseMaterial from '@/components/chooseMaterial'
import uploadModal from '@/components/uploadModal'

export default {
  name: 'selectMaterial',
  components: {
    chooseMaterial,
    uploadModal,
  },
  props: {
    type: {
      type: String,
      default: 'single',
      validator: val => ['single', 'multiple'].includes(val),
    },
    data: {
      type: [Array, Object],
      required: true,
    },
    placement: {
      type: String,
      default: 'left',
    },
    allow: {
      type: Array,
      default: () => ['image', 'video'],
      // validator: val => ['image', 'video', 'audio'].includes(val),
    },
  },
  data() {
    return {
      chooseVisible: false,
      showData: null,
      visible: false,
      uploadVisible: false,
      mode: 'image', // 上传modal 的type
    }
  },
  computed: {
    _placement() {
      return this.placement === 'left' ? 'bottomLeft' : 'bottomRight'
    },
  },
  watch: {
    data: {
      handler(val) {
        // 兼容处理下数据结构不一致  object TO array
        if (Array.isArray(val)) {
          this.showData = val
        } else {
          if (val.path || val.cover) {
            this.showData = [val]
          } else {
            this.showData = []
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    allowed(_type) {
      // 是否可以点击
      return this.allow.includes(_type)
    },
    onAdd() {
      this.chooseVisible = true
    },
    onClose() {
      this.visible ? (this.visible = false) : ''
    },
    onEdit() {
      // 延时处理
      setTimeout(() => {
        this.visible = true
      }, 1)
    },
    getChoose(data) {
      if (this.type === 'multiple') {
        this.$emit('change', this.showData.concat(data))
      } else {
        this.$emit('change', data)
      }
    },
    onPreview(item) {
      let tmp
      if (typeof item === 'string') {
        tmp = [
          {
            type: 'image',
            cover: item,
            path: item,
          },
        ]
      } else {
        tmp = this.showData.map(item => ({
          type: item.type,
          cover: item.path,
        }))
      }
      this.BjPreview(tmp, item.path)
    },
    onDelete(index) {
      this.showData.splice(index, 1)
      this.$emit('change', this.showData)
    },
    onLoad(_type) {
      if (!this.allowed(_type)) return
      this.mode = _type
      this.uploadVisible = true
    },
    onChange(e) {
      e.map(item => {
        item.cover = item.path
        item.id = item.file_id
      })
      this.getChoose(e)
    },
  },
}
</script>

<style lang="less" module>
.select {
  display: flex;

  .box {
    width: 80px;
    height: 80px;
    margin-right: 14px;
    line-height: 80px;
    text-align: center;
    border: 2px dashed #eee;

    .icon {
      color: #5c5c5c;
      font-size: 40px;
    }
  }

  .box:hover {
    border-color: @primary-color;
    cursor: pointer;

    .icon {
      color: @primary-color;
    }
  }

  .img {
    position: relative;
    display: inline-block;
    width: 80px;
    height: 80px;
    margin-right: 14px;
    margin-bottom: 14px;
    border: 2px solid #eee;

    img {
      width: 76px;
      height: 76px;
      object-fit: cover;
    }

    .type {
      position: absolute;
      top: 4px;
      right: 4px;
      color: #fff;
      font-size: 14px;
      line-height: 14px;
      cursor: default;
    }
  }

  .img:hover .btn {
    display: inline-block;
  }

  .btn {
    position: absolute;
    left: 0;
    display: none;
    width: 100%;
    line-height: 76px;
    text-align: center;
    background: rgba(0, 0, 0, 0.2);

    i {
      color: #fff;
      font-size: 18px;
    }

    i:first-child {
      margin-right: 12px;
    }

    .delete {
      color: @error-color;
    }
  }
}

.drop {
  width: 200px;
  height: 164px;
  padding: 10px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

  &-btn {
    width: 100%;
    height: 44px;
    padding: 0 12px;
    font-size: 14px;
    line-height: 44px;
    border-radius: 6px;
    cursor: pointer;

    i {
      margin-right: 8px;
      color: #000;
      font-size: 20px;
      vertical-align: -4px;
    }
  }

  &-btn:hover {
    color: @primary-color;
    background: #fafafa;

    i {
      color: @primary-color;
    }
  }

  .line {
    width: 100%;
    height: 1px;
    margin: 10px 0;
    border-top: 1px solid #eee;
  }

  &-tip {
    margin-bottom: 10px;
    color: #5c5c5c;
    font-size: 12px;
  }

  &-type {
    display: flex;
    justify-content: space-between;

    .drop-item {
      width: 54px;
      height: 54px;
      text-align: center;
      border-radius: 6px;
      cursor: pointer;

      i {
        color: #000;
        font-size: 20px;
      }
    }

    .drop-item:hover {
      color: @primary-color;
      background: #fafafa;

      i {
        color: @primary-color;
      }
    }

    .drop-item-no {
      width: 54px;
      height: 54px;
      color: #bbb;
      text-align: center;
      border-radius: 6px;
      cursor: not-allowed;

      i {
        color: #bbb;
        font-size: 20px;
        cursor: not-allowed;
      }
    }

    .drop-item-no:hover {
      background: #fafafa;
    }
  }
}

.flex1 {
  flex: 1;
}
</style>
